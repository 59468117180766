import React from 'react'
import styled from 'styled-components'
import { desktopBreakpoint, colors, desktopVW, mobileVW } from '../../../styles/index'
import FooterSubscribeForm from './FooterSubscribeForm'
import { useGlobalDictionaryQuery } from '../../../hooks'


interface Props {
  
}

export default function FooterSubscribe(props: Props) {

  const {
    
  } = props

  const dictionary = useGlobalDictionaryQuery()

  return (
    <Wrapper>
      <SubscribeTitle>
        {dictionary.newsletterText}
      </SubscribeTitle>
      <FooterSubscribeForm

      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100vw;
  background-color: white;
  position: relative;
  height: ${mobileVW(250)};
  
  ${desktopBreakpoint}{
    height: ${desktopVW(340)};  
  }
`;

const SubscribeTitle = styled.p`
  position: absolute;
  font-family: 'messinaMono';
  font-size: ${mobileVW(20)};
  color: ${colors.lightGrey};
  letter-spacing: -0.05em;
  top: ${mobileVW(30)};
  left: ${mobileVW(30)};
  width: 80%;


  ${desktopBreakpoint}{
    width: ${desktopVW(300)};
    top: ${desktopVW(65)};
    left: ${desktopVW(55)};
    font-size: ${desktopVW(18)};

  }
`;