import { decode } from 'shopify-gid';

export const useProductViewTracking = (
  StoreFrontID: string,
  title: string,
  price: number,
  location: { action: string; pathname: string },
  category: string
) => {
  const pushToDataLayer = () => {
    window.dataLayer.push({
      event: 'productDetailView',
      ecommerce: {
        currency: 'IDR',
        detail: {
          products: [
            {
              id: decode(StoreFrontID).id,
              slug: location.pathname,
              name: title,
              variant: decode(StoreFrontID),
              price,
              category,
            },
          ],
        },
      },
    });
  };

  if (location.action) {
    typeof window !== 'undefined' && window.dataLayer && pushToDataLayer();
  } else {
    setTimeout(() => {
      typeof window !== 'undefined' && window.dataLayer && pushToDataLayer();
    }, [process.env.GTM_DELAY]);
  }
};
