import React from 'react';
import styled from 'styled-components';
import { useGlobalDictionaryQuery } from '../../hooks/index';
type Props = {}

export default function FloatingButton({}: Props) {
  const dictionary = useGlobalDictionaryQuery();
  return (
    <>
      {dictionary?.whatsappNumber!='' && dictionary?.showFloatingButtonWhatsapp?(      <Wrapper>
       <a href={`https://web.whatsapp.com/send?phone=${dictionary.whatsappNumber}&text=`} target="_blank">
        <Image src={dictionary?.imageFloatingWhatsapp?.fluid?.src} alt='floating button'/>
       </a>
      </Wrapper>):null}
    </>
  )
}


const Wrapper = styled.div`
    z-index: 16777216;
    position: fixed;
    right: 14px;
    bottom: 50.5px;
    width: 60px;
    cursor: pointer;
    height: 60px;
`;

const Image = styled.img`
   width:100%;
   height:100%;
   object-fit:cover;
`;


