import React from 'react';
import { Link } from 'gatsby';
import { useLocale } from '../../hooks/layout/useLocale';

const LinkTool = ({ to, children }) => {
  let locale = useLocale();
  if (typeof locale === 'undefined') locale = location.href.includes('/id') ? 'id' : 'en';
  return <Link to={`/${locale}${to}`}>{children}</Link>;
};

export default LinkTool;
