import React, { useContext } from 'react'
import styled from 'styled-components'
import { desktopBreakpoint, colors, desktopVW, mobileVW, zIndex } from '../../styles/index'
import LinkTool from '../shared/LinkTool'
import Icons from '../../utils/Icons'
import MenuMobileMain from './header/MenuMobileMain'
import { SettingsContext } from '../../contexts'
import { navigate } from 'gatsby-link'
import {useGlobalSettingsQuery, useSearchQuery} from '../../hooks/index'
import {useSpring, animated, config} from 'react-spring'
import { useLocale } from '../../hooks/layout/useLocale'



interface Props {
  handleMobileMenuClick:()=>void
  mobileScrolled: boolean
  menuData: {} | {menus: [], __typename: string}
  isUserLogged: boolean
  pageType: string
  backgroundHeader: boolean
  headerColor: string
}

export default function HeaderContentMobile(props: Props) {

  const {
    handleMobileMenuClick,
    mobileScrolled,
    menuData,
    isUserLogged,
    pageType,
    backgroundHeader,
    headerColor,
  } = props

  const {
    isMenuOpen, 
    setMenuOpen,
    setIsCartOpen,
  }: {
    isMenuOpen: any
    setMenuOpen: any
    setIsCartOpen: any
  } = useContext(SettingsContext)

  const {
    isHeaderBlackOnDesktop
  }: {
    isHeaderBlackOnDesktop:{}
  } = useGlobalSettingsQuery()

  const headerMobileSpring = useSpring({
    borderBottom: backgroundHeader ? '1px solid rgba(38, 37, 37, 1)' : '1px solid rgba(38, 37, 37, 0)',
    opacity: backgroundHeader ? 1 : 0,
    config: config.stiff
  })

  const currLocale = useLocale();
  const search = useSearchQuery();

  const getCurrentColor = () => {
    if (mobileScrolled) {
      return colors.black;
    } else if (pageType === 'General') {
      return headerColor;
    } else if (isHeaderBlackOnDesktop[pageType]) {
      return colors.black;
    }
    return colors.white;
  };

  return (
    <Wrapper>
      <HeaderBackground style={{ ...headerMobileSpring }} />

      <MenuButtonWrapper>
        <MenuButton
          handleHeaderColor={getCurrentColor()}
          aria-label="menu"
          type="button"
          onClick={() => handleMobileMenuClick()}
        >
          Menu
        </MenuButton>
      </MenuButtonWrapper>

      <LogoWrapper>
        <LinkTool to="/" onClick={() => setMenuOpen(false)}>
          <Icons name={'logoMainHeader'} data={getCurrentColor()} />
        </LinkTool>
      </LogoWrapper>

      <HeaderRightWrapper>
        <SearchButton
          aria-label="Search product"
          type="button"
          onClick={() => navigate(`/${currLocale}/${search.slug}/`)}
        >
          <Icons name="search" data={getCurrentColor()} />
        </SearchButton>
        <CartButton aria-label="Open cart" type="button" onClick={() => setIsCartOpen(true)}>
          <Icons name="cart" data={getCurrentColor()} />
        </CartButton>
      </HeaderRightWrapper>

      <AsideWrapperMobile>
        <MenuMobileMain menuData={menuData} isUserLogged={isUserLogged} />
      </AsideWrapperMobile>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: ${mobileVW(70)};
  padding: 0;
`;

const MenuButtonWrapper = styled.div`
  height: 100%;
  width: ${mobileVW(80)};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: ${zIndex.header + 1};
`;

const MenuButton = styled.button`
  font-family: "messinaMono", "Courier New", Courier, monospace;
  font-size: ${mobileVW(18)};
  letter-spacing: -0.05em;
  color: ${({handleHeaderColor})=> handleHeaderColor};
  height: 100%;
  width: ${mobileVW(80)};
  padding: 0 ${mobileVW(20)};
`;

const LogoWrapper = styled.div`
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-family: "messinaMono", "Courier New", Courier, monospace;
  font-size: ${desktopVW(14)};
  letter-spacing: -0.05em;
  position: absolute;
  top: 0;
  left: 50vw;
  transform: translateX(-50%);
  z-index: ${zIndex.header + 1};
`;

const HeaderRightWrapper = styled.div`
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: bottom;
  padding-top: 0;
`;

const CartButton = styled.button`
  z-index: ${zIndex.surface};
  padding: ${mobileVW(4)} ${mobileVW(20)} 0 ${mobileVW(15)};
  color: ${({dColor})=>dColor};
`;

const SearchButton = styled.button`
  z-index: ${zIndex.surface};
  padding: ${mobileVW(4)} ${mobileVW(12)} 0 ${mobileVW(12)};
`;

const AsideWrapperMobile = styled.div`
  position: absolute;
  top: -1;
  left: 0;
  width: 0;
  height: 100vh;
  z-index: ${zIndex.satan};
`;

const HeaderBackground = styled(animated.div)`
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: ${mobileVW(70)};
  z-index: ${zIndex.backgrounds};
  will-change: opacity;
  background-color: ${colors.frappuccino};
`;