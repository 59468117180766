import { decode } from 'shopify-gid';

export const useAddToCartTracking = (
  StoreFrontID: string,
  title: string,
  price: number,
  location: { pathname: string },
  amount: number,
  category: string
) => {
  const selectedAmount = amount || 1;
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push({
      event: 'addToCart',
      ecommerce: {
        currencyCode: 'IDR',
        add: {
          products: [
            {
              id: decode(StoreFrontID).id,
              slug: location.pathname,
              name: title,
              variant: decode(StoreFrontID),
              price,
              amount: selectedAmount,
              value: price * selectedAmount,
              category,
            },
          ],
        },
      },
    });
  }
};
