function addCommas(nStr) {
  nStr += '';
  const x = nStr.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? `.${x[1]}` : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return x1 + x2;
}

const formatPrice = (price, currency) => {
  const num = parseFloat(price);
  // const format = num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '1,');
  const format = addCommas(num);
  return `${currency} ${format}`;
};

export default formatPrice;
