import React from "react";
import {
  StoreContextProvider,
  SettingsProvider,
  LocaleContext,
  ShopContext
} from "./src/contexts";

import Layout from "./src/components/layout/Layout";

export const wrapRootElement = ({ element }) => (
  <StoreContextProvider>{element}</StoreContextProvider>
);

export const wrapPageElement = ({
  element,
  props: {
    pageContext: { shopMenuHeader, pageType, allLocaleSlugs, node_locale, allProductsTitleSlug, navbarColor, protectionProducts },
  },
}) => {

  return (
    <SettingsProvider allLocaleSlugs={allLocaleSlugs}>
      <LocaleContext.Provider value={{ lang: node_locale }}>
        <ShopContext.Provider value={{protectionProducts}}>
          <Layout allProductsTitleSlug={allProductsTitleSlug} shopMenuHeader={shopMenuHeader} pageType={pageType} navbarColor={navbarColor}>
            {element}
          </Layout>
        </ShopContext.Provider>
      </LocaleContext.Provider>
    </SettingsProvider>
  );
};
