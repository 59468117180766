import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import {
  desktopBreakpoint,
  colors,
  desktopVW,
  mobileVW,
  zIndex,
} from "../../styles/index";
import LinkTool from "../shared/LinkTool";
import Icons from "../../utils/Icons";
import MenuMobileMain from "./header/MenuMobileMain";
import { SettingsContext } from "../../contexts";
import { navigate } from "gatsby-link";
import { useSearchQuery } from "../../hooks/index";
import { useSpring, animated, config } from "react-spring";
import HeaderDesktopAsideMain from "./header/HeaderDesktopAsideMain";
import LangSwitch from "../shared/LangSwitch";
import { useLang } from "../../hooks/layout/useLang";
import { useLocale } from "../../hooks/layout/useLocale";

interface Props {
  closeBothMenus: () => void;
  shopMenuHeader: [];
  handleMenuClick: () => void;
  pageType: string;
  isUserLogged: boolean;
  menuData: {} | { menus: []; __typename: string };
  handleBackgroundclick: () => string;
  handleSubMenuClick: () => void;
  setFixedBorderOn: () => void;
  fixedBorderOn: string;
  headerColor: string;
}

export default function HeaderContentDesktop(props: Props) {
  const {
    closeBothMenus,
    shopMenuHeader,
    handleMenuClick,
    pageType,
    isUserLogged,
    menuData,
    handleBackgroundclick,
    handleSubMenuClick,
    setFixedBorderOn,
    fixedBorderOn,
    headerColor,
  } = props;

  const search = useSearchQuery()

  const {
    isMenuOpen,
    setIsCartOpen,
    secondMenuVisible,
  }: {
    isMenuOpen: any;
    setIsCartOpen: any;
    secondMenuVisible: any;
  } = useContext(SettingsContext);

  const menuWrapperSpring = useSpring({
    pointerEvents: isMenuOpen ? "auto" : "none",
    cursor: isMenuOpen ? "pointer" : "auto",
    opacity: isMenuOpen ? 1 : 0,

    config: {
      tension: 200,
      friction: 20,
    },
  });

  const menuChildSpring = useSpring({
    opacity: secondMenuVisible ? 0 : 1,
    pointerEvents: secondMenuVisible ? "none" : "auto",
    config: {
      tension: 210,
      friction: 20,
      clamp: true,
    },
    delay: 300,
  });

  const HeaderMenuchild = ({ title }: { title: string }) => {
    const [borderSpring, setBorderSpring] = useSpring(() => ({
      transform: "translateX(-105%)",
      config: {
        tension: 210,
        friction: 34,
      },
    }));
    return (
      <BorderOnHoverWrapper
        onMouseMove={() => setBorderSpring({ transform: "translateX(0%)" })}
        onMouseLeave={() => setBorderSpring({ transform: "translateX(-105%)" })}
        onClick={() => setFixedBorderOn(title)}
      >
        {title}
        <Border style={{ ...borderSpring }} color={headerColor} />
        {fixedBorderOn === title && <Border color={headerColor} />}
      </BorderOnHoverWrapper>
    );
  };

  const currLocale = useLocale();

  const allLocaleSlugs = useLang();

  return (
    <Wrapper>
      <HeaderMenuWrapper>
        <LogoWrapper>
          <LinkTool to={`/`} onClick={() => closeBothMenus()}>
            <Icons name={"logoMainHeader"} data={headerColor} />
          </LinkTool>
        </LogoWrapper>
        {shopMenuHeader &&
          shopMenuHeader.map(
            ({ title, __typename, menus, target, products }) => (
              <MenuChild handleHeaderColor={headerColor} key={title}>
                <animated.div style={{ ...menuChildSpring }}>
                  {__typename === "ContentfulComponentMenuGroup" ? (
                    <button
                      type="button"
                      aria-label={title}
                      onClick={() => {
                        handleMenuClick(__typename, { menus, __typename });
                      }}
                    >
                      <HeaderMenuchild
                        title={title}
                        borderColor={headerColor}
                      />
                    </button>
                  ) : (
                    <button
                      type="button"
                      aria-label={title}
                      onClick={() =>
                        handleMenuClick(
                          __typename,
                          `${currLocale}/${target.slug}`
                        )
                      }
                    >
                      <HeaderMenuchild
                        title={title}
                        borderColor={headerColor}
                      />
                    </button>
                  )}
                </animated.div>
              </MenuChild>
            )
          )}
      </HeaderMenuWrapper>
      <HeaderRightWrapper>
        {/* this expression is needed to hide this section from the 404 page */}
        {pageType && (
          <>
            <LangSwitch textColor={headerColor} allLocaleSlugs={allLocaleSlugs} />
            <CartButton
              aria-label="Account"
              type="button"
              onClick={() =>
                navigate(
                  isUserLogged
                    ? `/${currLocale}/account/`
                    : `/${currLocale}/account/login`)
              }
              dColor={headerColor}
            >
              {isUserLogged ? "My account" : "Login"}
            </CartButton>
            <CartButton
              aria-label="Search"
              type="button"
              onClick={() => navigate(`/${currLocale}/${search.slug}/`)}
            >
              <Icons name="search" data={headerColor} />
            </CartButton>
            <CartButton
              aria-label="Open cart"
              type="button"
              onClick={() => setIsCartOpen(true)}
            >
              <Icons name="cart" data={headerColor} />
            </CartButton>
          </>
        )}
      </HeaderRightWrapper>
      <AsideWrapper>
        <Background
          style={{ ...menuWrapperSpring }}
          onClick={() => handleBackgroundclick()}
        />
        <HeaderDesktopAsideMain
          menuData={menuData}
          handleSubMenuClick={handleSubMenuClick}
          closeBothMenus={closeBothMenus}
        />
      </AsideWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: ${mobileVW(70)};
  padding: 0;
  position: relative;

  ${desktopBreakpoint} {
    top: 0;
    height: ${desktopVW(80)};
    padding: 0 ${desktopVW(30)} 0 ${desktopVW(62)};
  }
`;

const HeaderMenuWrapper = styled.div`
  height: 100%;
  width: ${mobileVW(80)};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: ${zIndex.header + 1};
  ${desktopBreakpoint} {
    width: auto;
  }
`;

const MenuChild = styled.div`
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-right: ${desktopVW(34)};
  padding-bottom: ${desktopVW(10)};
  font-family: "messinaMono", "Courier New", Courier, monospace;
  font-size: ${desktopVW(14)};
  letter-spacing: -0.05em;

  button {
    font-family: "messinaMono", "Courier New", Courier, monospace;
    font-size: ${desktopVW(14)};
    letter-spacing: -0.05em;
    color: ${({ handleHeaderColor }) => handleHeaderColor};
  }
`;

const LogoWrapper = styled.div`
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-family: "messinaMono", "Courier New", Courier, monospace;
  font-size: ${desktopVW(14)};
  letter-spacing: -0.05em;
  position: absolute;
  top: 0;
  left: 50vw;
  transform: translateX(-50%);
  z-index: ${zIndex.header + 1};

  a,
  a:focus {
    outline: none;
  }

  ${desktopBreakpoint} {
    justify-content: flex-end;
    padding: 0 ${desktopVW(69)} ${desktopVW(10)} 0;
    position: relative;
    top: auto;
    left: auto;
    transform: translateX(0%);
  }
`;

const AsideWrapper = styled.div`
  position: absolute;
  top: -1;
  left: 0;
  width: 0;
  height: 100vh;
  z-index: ${zIndex.header - 1};
`;

const Background = styled(animated.div)`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: ${zIndex.header - 2};
  background-color: rgba(0, 0, 0, 0.4);
  /* backdrop-filter: blur(2.5vw);  */
`;

const HeaderRightWrapper = styled.div`
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: bottom;
  align-items: flex-start;
  padding-top: ${desktopVW(43)};
  & > * {
    padding: ${desktopVW(10)};
    margin-right: ${desktopVW(10)};
  }
  & > *:last-child {
    margin-right: 0;
  }
`;

const CartButton = styled.button`
  z-index: ${zIndex.surface};
  color: ${({ dColor }) => dColor};
  font-family: "messinaMono", "Courier New", Courier, monospace;
  font-size: ${desktopVW(14)};
  letter-spacing: -0.05em;
`;

const Border = styled(animated.div)`
  position: absolute;
  bottom: ${desktopVW(0)};
  left: 0;
  height: ${desktopVW(1)};
  background-color: ${({ color }) => color};
  width: 100%;
`;

const BorderOnHoverWrapper = styled(animated.div)`
  position: relative;
  overflow-x: hidden;
  display: inline-block;
`;
