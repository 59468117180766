/* eslint-disable no-use-before-define */

import React, { useEffect, useState, useContext } from 'react';
import { SettingsContext } from '../../contexts';

type Props = {};

export default function GetAllPproduts({}: Props) {
  const {
    allProductShopify,
    setAllProductShopify,
  }: {
    allProductShopify: any;
    setAllProductShopify: any;
  } = useContext(SettingsContext);

  const [nextPage, setNextPage] = useState('');

  const productQuery = () => `
    query {
      products(first: 250  ) {
        edges {
          node {
            id
            title
            handle
            variants(first:10) {
              edges {
                node {
                  sku
                  title
                  quantityAvailable
                }
              }
            }
          }
          cursor
        }
      pageInfo {
        hasNextPage
      }
    }
    }
  `;

  const productQueryWithCursor = () => `
    query {
      products(first: 250 after: "${nextPage}"  ) {
        edges {
          node {
            id
            title
            handle
            variants(first:10) {
              edges {
                node {
                  sku
                  title
                  quantityAvailable
                }
              }
            }
          }
          cursor
        }
      pageInfo {
        hasNextPage
      }
    }
    }
  `;
  const GRAPHQL_URL = `https://${process.env.GATSBY_SHOPIFY_STORE}/api/2022-01/graphql.json`;

  const GRAPHQL_BODY = () => ({
    async: true,
    crossDomain: true,
    method: 'POST',
    headers: {
      'X-Shopify-Storefront-Access-Token': process.env.GATSBY_SHOPIFY_ACCES_TOKEN,
      'Content-Type': 'application/graphql',
    },
    body: nextPage != '' ? productQueryWithCursor() : productQuery(),
  });

  useEffect(() => {
    fetch(GRAPHQL_URL, GRAPHQL_BODY())
      .then(res => res.json())
      .then(product => {
        setAllProductShopify(data => [...data, ...product.data.products.edges]);
        const nextpage = product.data.products.pageInfo.hasNextPage;
        if (nextpage) {
          setNextPage(product.data.products.edges[product.data.products.edges.length - 1].cursor);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, [nextPage]);

  return null;
}
