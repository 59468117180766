import { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { LocaleContext } from "../../contexts";

export const useGlobalComponentsQuery = () => {
  const { lang } = useContext(LocaleContext);

  const {
    allContentfulGlobalComponents: { edges },
  } = useStaticQuery(
    graphql`
      query globalComponents {
        allContentfulGlobalComponents {
          edges {
            node {
              node_locale
              klaviyoNewsletterListId
              newsletterTitleDomestic
              newsletterTitleRow
              newsletterParagraphDomestic
              newsletterParagraphRow
              newsletterPopupImage {
                fluid(maxWidth: 800, quality: 50) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              seoImage {
                file {
                  url
                }
              }
              thumbnailPlaceholder {
                fluid(maxWidth: 1200, quality: 50) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `
  );

  let filteredGlobalComponentsQuery = edges.filter(
    ({ node: { node_locale } }) => node_locale === lang
  );

  let global = filteredGlobalComponentsQuery[0]?.node;
  return global;
};
