import styled from 'styled-components'
export const desktopBreakpoint = `@media (min-width: 1024px)`

export const mobileVW = px => {
  return `${(px / 414) * 100}vw`
}

export const desktopVW = px => {
  return `${(px / 1440) * 100}vw`
}

export const ease = {
  expo: 'cubic-bezier(1.000, 0.000, 0.000, 1.000)',
}

export const mobileContentSidePadding = mobileVW(30)

export const topBarHeightMobile = mobileVW(70)

export const topBarHeightDesktop = desktopVW(30)

export const colors = {
  black: '#262525',
  blackButABitDarker: '#353535',
  white: '#FFFFFF',
  frappuccino: '#F2EFEC',
  cappuccino: "#C2B9B5",
  halfTransparentgrey: 'rgba(38, 37, 37, 0.5)',
  subtleGrey: '#707070',
  lightGrey: '#C0C0C0',
  tomato: '#CF4141',
  fakeProductBackground: '#E3DDD6',
  thatSpecificColorThatTheDesignerUsedJustInTwoP: '#252323',
  IHopeThisIsTheLastBeigeNuance: '#E5DED8',
  lightGreyButLighter: '#E9E9E9',
  noItWasNotTheLastBeigeNouance: "#E5DDD6",
}

export const zIndex = {
  basement: -10,
  backgrounds: 10,
  dots: 20,
  surface: 30,
  high: 50,
  header: 100,
  satan: 666,
}

export const getHeaderColor = (page) => {
  if (page === '/') {
    return colors.creamDark
  } else {
    return colors.black
  }
}

export const DesktopView = styled.div`
  display: none;
  ${desktopBreakpoint}{
    display: block;
  }
`

export const MobileView = styled.div`
  display: block;
  ${desktopBreakpoint}{
    display: none;
  }
`

export const SpacerDesktop = styled.div`
    display: none;
  ${desktopBreakpoint}{
    display: block;
    ${
    ({horizontal, sWidth })=>horizontal ? `
      height: 100%;
      width: ${desktopVW(sWidth)};
    ` : null
    }

    ${
      ({vertical, sHeight })=>vertical ? `
        width: 100%;
        height: ${desktopVW(sHeight)};
      ` : null
    }
  }
`

export const SpacerMobile = styled.div`
    display: block;
    ${
    ({horizontal, sWidth })=>horizontal ? `
      height: 100%;
      width: ${mobileVW(sWidth)};
    ` : null
    }

    ${
      ({vertical, sHeight })=>vertical ? `
        width: 100%;
        height: ${mobileVW(sHeight)};
      ` : null
    }
  ${desktopBreakpoint}{
    display: none;

  }
`

export const SpringConfig = {
  microInteraction: {
    tension: 380,
    friction: 35
  }
}
