import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { useSpring, animated, config } from 'react-spring';
import {
  desktopVW,
  mobileVW,
  desktopBreakpoint,
  colors,
  zIndex,
  MobileView,
  DesktopView,
} from '../../styles';
import { useLocale } from '../../hooks/layout/useLocale';
import Icons from '../../utils/Icons';
import { SettingsContext } from '../../contexts';

const LangSwitch = ({ textColor, allLocaleSlugs }) => {
  const langs = Object.keys(allLocaleSlugs);
  const currLocale = useLocale();
  const location = useLocation();
  const [quantity, setQuantity] = useState(1);
  const [desktopSelectOpen, setdesktopSelectOpen] = useState(false);

  const {
    resetFilters,
  }: {
    resetFilters: () => void;
  } = useContext(SettingsContext);

  const handleLanguageSelection = e => {
    resetFilters();
    const newLang = e;
    setTimeout(() => setdesktopSelectOpen(!desktopSelectOpen), 300);
    if (allLocaleSlugs[newLang] === 'account') {
      // Account pages have the same url for different languages. This way we can preserve the account activate hash in the url when switching locales
      let newPath = window.location.pathname;
      langs.forEach(locale => {
        newPath = newPath.replace(`/${locale}/`, `/${newLang}/`);
      });
      navigate(newPath);
    } else {
      const newPath = `/${newLang}/${allLocaleSlugs[newLang]}`;
      navigate(newPath);
    }
  };

  const selectSpring = useSpring({
    transform: desktopSelectOpen ? 'translateY(0%)' : 'translateY(-105%)',
  });

  return (
    <SwitchContainer>
      {/* <SwitchSelect
        color={textColor}
        onChange={handleLanguageSelection}
        value={currLocale}
      >
        {langs.map((lang) => {
          const langFormatted = lang === "en" ? "En" : "Nl";
          return (
            <option key={lang} value={lang}>
              {langFormatted}
            </option>
          );
        })}
      </SwitchSelect>
      <SwitchArrow>&#x2304;</SwitchArrow>  */}
      <DesktopView>
        <SizeSelect color={textColor}>
          <label htmlFor="sizes">{currLocale}</label>
          <VariantSelectHeaderDesktop
            onClick={() => {
              setTimeout(() => setdesktopSelectOpen(!desktopSelectOpen), 300);
            }}
          />
          <IconWrapper
            onClick={() => {
              setTimeout(() => setdesktopSelectOpen(!desktopSelectOpen), 300);
            }}
          >
            <Icons name={desktopSelectOpen ? 'arrowUp' : 'arrowDown'} color={textColor} />
          </IconWrapper>
          <SelectOptionsDesktop>
            <animated.div style={{ ...selectSpring, willChange: 'transform' }}>
              {langs.map(lang => (
                <OptionChildDesktop key={lang} onClick={() => handleLanguageSelection(lang)}>
                  <p>{lang}</p>
                </OptionChildDesktop>
              ))}
            </animated.div>
          </SelectOptionsDesktop>
        </SizeSelect>
      </DesktopView>
    </SwitchContainer>
  );
};

const SwitchContainer = styled.div`
  display: flex;
`;

const SwitchSelect = styled.select`
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  color: ${({ color }) => color};
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  font-size: ${desktopVW(14)};
  letter-spacing: -0.05em;
  padding-right: 6px;
  outline: none;
`;

const SwitchArrow = styled.div`
  opacity: 0.5;
  width: 10px;
  height: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SizeSelect = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  color: ${({ color }) => color};
  padding-bottom: ${mobileVW(10)};
  margin-bottom: ${mobileVW(30)};
  position: relative;

  label {
    font-size: ${mobileVW(18)};
    ${desktopBreakpoint} {
      font-size: ${desktopVW(14)};
    }
    &::first-letter {
      text-transform: uppercase;
    }
  }

  ${desktopBreakpoint} {
    padding-bottom: ${desktopVW(10)};
    padding-right: ${desktopVW(25)};
    margin-bottom: ${desktopVW(20)};
    font-size: ${desktopVW(14)};
  }
`;

const VariantSelectHeaderDesktop = styled.div`
  color: ${colors.black};
  width: auto;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  color: ${colors.black};
  border: none;
  font-size: ${desktopVW(14)};
  cursor: pointer;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding-top: ${mobileVW(4)};

  ${desktopBreakpoint} {
    right: ${desktopVW(-5)};
    padding-top: 0;
    cursor: pointer;
  }
`;

const SelectOptionsDesktop = styled(animated.div)`
  position: absolute;
  top: ${desktopVW(26)};
  right: 0;
  width: auto;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  overflow: hidden;
  z-index: ${zIndex.surface + 2};
`;

const OptionChildDesktop = styled.div`
  user-select: none;
  pointer-events: all;
  position: relative;
  color: ${colors.frappuccino};
  height: ${desktopVW(50)};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 ${desktopVW(25)};
  background-color: ${colors.black};
  z-index: ${zIndex.surface + 2};
  cursor: pointer;

  p {
    pointer-events: none;
    &::first-letter {
      text-transform: uppercase;
    }
  }
`;

export default LangSwitch;
